import { useContext, useEffect } from 'react';

import { AppMetaContext } from '@core/context';

import useRouteData from './useRouteData';

export default function useRoute(props, setup) {
  const { updateAppMeta } = useContext(AppMetaContext);

  const {
    data: { meta, ...state } = {},
    error,
    loading,
    mutate,
    redirected,
    redirectedPath,
    validating,
  } = useRouteData(props);

  useEffect(() => {
    if (meta) {
      if (typeof setup === 'function') setup(meta);
      updateAppMeta(meta);
    }
  }, [meta]); // eslint-disable-line react-hooks/exhaustive-deps

  return { loading, state, meta, redirected, redirectedPath, error, mutate, validating };
}

import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { UrlManager, ProjectContext } from '@core/context';

const useProjectUrl = ({ endpoint, origin = 'readme.local:3000', protocol = '//', ...opts } = {}) => {
  const { search } = useLocation();
  const { project } = useContext(ProjectContext);
  const { flags, parent = {} } = project;

  if (typeof window === 'undefined') return '';

  // UrlManager accepts childrenProjects as an array of strings,
  // so we create a slim representation of the `parent` object
  // called `simpleParent` for the UrlManager to consume
  let simpleParent;
  const siblings = project.siblings || [];

  if (siblings.length) simpleParent = { childrenProjects: siblings.map(child => child.subdomain) };
  const urlManager = new UrlManager({
    child: Boolean(siblings.length),
    hasOneChild: !!flags?.singleProjectEnterprise,
    parent: simpleParent,
    project,
  });
  // eslint-disable-next-line prefer-const
  let [path, query = search] = urlManager.relative(endpoint).split('?');

  const searchParams = new URLSearchParams(query);
  searchParams.set('json', 'on');

  if (searchParams.toString().length > 0) {
    path = `${path}?${searchParams.toString()}`;
  }

  // `local` is the NODE_ENV we use for the reference playground
  // environment (from webpack-dev-server). It does not support
  // subdomains, so we have to return with a full URL.
  // In all other environments, we have the rest of our routing
  // setup so we dont need the full URL and can just return the path
  if (process.env.NODE_ENV !== 'local') return path;

  const subdomain = opts?.subdomain || parent?.subdomain || project.subdomain;
  const parts = [protocol, `${subdomain}.${origin}`, path];

  return parts.join('');
};

export default useProjectUrl;

import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, Prompt, useHistory, useLocation } from 'react-router-dom';

import {
  APIBaseUrlContext,
  BaseUrlContext,
  ConfigContext,
  EnterpriseParentContext,
  GlossaryContext,
  ProjectContext,
  UserContext,
  VariablesContext,
  VersionContext,
} from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useIsStagingViewer from '@core/hooks/useIsStagingViewer';
import useRoute from '@core/hooks/useRoute';
import useUserPermissions from '@core/hooks/useUserPermissions';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import MarkdownEditor from '@ui/MarkdownEditor';
import { serialize } from '@ui/MarkdownEditor/editor';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '@ui/Modal';
import Notification, { notify } from '@ui/Notification';
import Sidebar from '@ui/Sidebar';
import Spinner from '@ui/Spinner';
import Textarea from '@ui/Textarea';
import Tooltip from '@ui/Tooltip';

import generateStagingUrl from './generateStagingUrl';
import classes from './style.module.scss';

const fitTextareaToContent = target => {
  target.style.height = '';
  target.style.height = `${target.scrollHeight}px`;
};

const StagingOutLink = ({ to = '', children }) => {
  const { stagingEnv, isParent } = useContext(EnterpriseParentContext);
  const isStagedProd = stagingEnv === 'prod';
  const { domain } = useContext(ConfigContext);
  const { project } = useContext(ProjectContext);

  const uri = !isStagedProd ? to : generateStagingUrl(to, domain, project, isParent);

  const LinkTag = isStagedProd ? 'a' : Link;
  const LinkProps = {
    children,
    target: isStagedProd && '_blank',
    [isStagedProd ? 'href' : 'to']: uri,
  };

  return <LinkTag {...LinkProps} />;
};

StagingOutLink.propTypes = { to: PropTypes.string };

const SuggestionsEditor = ({ sidebars, activeDoc, ...props }) => {
  const $form = useRef();
  const $excerpt = useRef();
  const $modal = useRef();
  const bem = useClassy(classes, 'SuggestionsEditor');
  const baseUrl = useContext(BaseUrlContext);
  const apiBaseUrl = useContext(APIBaseUrlContext);
  const history = useHistory();
  const location = useLocation();
  const terms = useContext(GlossaryContext);
  const vars = useContext(VariablesContext);
  const { domainFull } = useContext(ConfigContext);
  const { project } = useContext(ProjectContext);
  const { stagingEnv } = useContext(EnterpriseParentContext);
  const user = useContext(UserContext);
  const stagingViewer = useIsStagingViewer(user, project);
  // This is using the legacy API so this is intentionally
  // using version_clean instead of version
  const { version_clean: versionClean, version } = useContext(VersionContext);
  const { isLoggedIn, isAdminUser } = useUserPermissions();
  const {
    state: { doc, ...state },
    loading,
    mutate,
  } = useRoute(props);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [submission, setSubmission] = useState(false);
  const [editorValue, setEditorValue] = useState();

  useEffect(() => {
    if (!isLoggedIn) {
      window.location = `${domainFull}/to/${project.subdomain}?redirect=${location.pathname}`;
    }

    const modalRef = $modal.current;
    return () => {
      modalRef?.toggle(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ($excerpt.current) fitTextareaToContent($excerpt.current);
  }, [doc?.excerpt]);

  useEffect(() => {
    setPristine(true);
    return () => {
      // eslint-disable-next-line no-param-reassign, react-hooks/exhaustive-deps
      activeDoc = '';
    };
  }, [location.pathname, $excerpt.current]);

  const discard = () => history.push(`/docs/${doc?.slug}`);

  const submit = async suggestion => {
    if (!pristine) {
      setIsSubmitting(suggestion ? 'suggest' : 'save');
      const body = editorValue ? serialize({ children: editorValue }) : doc?.body;
      const data = Object.fromEntries(new FormData($form.current).entries());

      const base = apiBaseUrl === '/' ? '' : apiBaseUrl;
      const endpoint = `${suggestion ? 'suggested' : 'save'}-edits`;
      const uri = `${base}/api/v${versionClean}/${endpoint}`;

      let update = {
        ...data,
        body,
        slug: doc?.slug,
        _id: doc?._id,
        edited: true,
      };
      if (suggestion) {
        update = {
          ...suggestion,
          edited: update,
        };
      }

      const xhr = await fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN') ?? '',
        },
        body: JSON.stringify(update),
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
      });

      let res = await xhr.text();
      try {
        res = JSON.parse(res);
      } catch (e) {
        /* console.error("Couldn't parse response JSON.")
         */
      }

      if (!suggestion) {
        if (res.toLowerCase() === 'saved') {
          /* successful save
           */
          const updatedDoc = { ...doc, ...data, body };
          mutate({ ...state, doc: updatedDoc }, { revalidate: true, populateCache: false });
          setPristine(true);
          history.push(`/docs/${doc.slug}`);
        }
      } else if (res._id) {
        /* successful suggestion
         */
        setSubmission(res);
        setPristine(true);
      } else {
        notify(
          <Notification dismissible kind="error" position="bottom-center">
            Couldn’t {suggestion ? 'submit suggestion' : 'save'}…
          </Notification>,
        );
      }
      setIsSubmitting(false);
    }
  };

  const CloseButton = (
    <Tooltip content={submission ? `Back to ${doc?.title || 'doc'}` : 'Continue editing…'} delay={500}>
      <Button
        circular
        ghost
        kind="minimum"
        onClick={() => {
          if (!submission) $modal.current.toggle(false);
          else discard();
        }}
        size="sm"
      >
        <Icon name="x" />
      </Button>
    </Tooltip>
  );

  return (
    <>
      <Prompt
        message={() => 'Are you sure you want to leave this page? Any unsaved changes will be lost.'}
        when={!pristine}
      />
      <div key={location.pathname} className={bem('&', loading && '_loading', 'rm-Guides', 'rm-SuggestionsEditor')}>
        <div className={bem('rm-Edits', 'rm-Container rm-Container_flex')}>
          <Sidebar activeDoc={activeDoc} categories={sidebars?.docs || []} pathRoot="edit" />
          {loading ? (
            <div className={bem('-spinner')}>
              <Spinner size="lg" />
            </div>
          ) : (
            <div className={bem('-content')}>
              <form ref={$form} className={bem('-form')} onChange={() => setPristine(false)}>
                <header className={bem('-header')}>
                  <div className={bem('-header-actions')}>
                    <h1 className={bem('-header-title')}>
                      <input defaultValue={doc?.title} name="title" />
                    </h1>
                    <Tooltip content={pristine ? 'Cancel' : 'Discard changes…'} delay={[500, 0]}>
                      <Button ghost kind="destructive" onClick={discard} size="sm">
                        <Icon name="x-circle" />
                      </Button>
                    </Tooltip>
                    {!!isAdminUser && (
                      <Tooltip content="Publish as admin…" delay={[1000, 0]}>
                        <Button
                          disabled={pristine}
                          kind="primary"
                          loading={isSubmitting === 'save'}
                          onClick={e => {
                            e.preventDefault();
                            submit();
                          }}
                          outline
                          size="sm"
                          type="submit"
                        >
                          <Icon name="save" />
                          Save
                        </Button>
                      </Tooltip>
                    )}
                    <Button
                      disabled={pristine}
                      onClick={e => {
                        e.preventDefault();
                        $modal.current.toggle();
                      }}
                      size="sm"
                    >
                      <Icon name="suggested-edits" />
                      Suggest
                    </Button>
                  </div>
                  <textarea
                    ref={$excerpt}
                    className={bem('-header-excerpt')}
                    defaultValue={doc?.excerpt}
                    name="excerpt"
                    onInput={e => fitTextareaToContent(e.target)}
                    placeholder="Excerpt…"
                    rows={1}
                  />
                </header>
              </form>
              <hr />
              <main className={bem('-body', 'markdown-body')}>
                <MarkdownEditor
                  // This logic disallows HTML blocks if the user is not an admin; otherwise the project must explicitly allowed unsafe HTML suggestions for non-admins (for example Wiz).
                  disallowHtmlBlocks={!isAdminUser && !project?.flags?.allowUnsafeCustomHtmlSuggestionsFromNonAdmins}
                  doc={doc?.body}
                  domainFull={domainFull}
                  glossaryTerms={terms}
                  onChange={update => {
                    setEditorValue(update);
                    setPristine(false);
                  }}
                  projectBaseUrl={baseUrl}
                  reusableContentMode="suggested-edits"
                  subdomain={project.subdomain}
                  useReusableContent={false}
                  variableDefaults={vars.defaults}
                  version={version}
                />
              </main>
            </div>
          )}
        </div>
      </div>

      <Modal
        ref={$modal}
        onClose={eventType => {
          if (submission && ['escapeKey', 'clickOut'].includes(eventType)) {
            discard();
          }
        }}
        size="md"
        target="#suggestion-submit-modal"
        verticalCenter
      >
        <form id="suggestion-submit-details">
          <ModalHeader>
            <div>
              <h2 style={{ margin: 0 }}>Suggest Edits</h2>
              <small>
                on <em>{doc?.title}</em>
              </small>
            </div>
            {CloseButton}
          </ModalHeader>
          {!submission ? (
            <>
              <ModalBody>
                <FormGroup htmlFor="name" label="Title">
                  <Input name="name" required={true} style={{ width: '100%' }} type="text" />
                </FormGroup>
                <br />
                <FormGroup description="(optional)" htmlFor="comment" label="Description">
                  <Textarea autoGrow name="comment" placeholder="A longer description of your changes…" resize="none" />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  loading={isSubmitting === 'suggest'}
                  onClick={e => {
                    const form = $modal.current.el.current.querySelector('form#suggestion-submit-details');
                    if (form.checkValidity()) {
                      e.preventDefault();
                      const data = Object.fromEntries(new FormData(form).entries());
                      submit(data);
                    }
                  }}
                  size="sm"
                  type="submit"
                >
                  <Icon name="suggested-edits" />
                  Submit
                </Button>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalBody>
                Your suggestion has been submitted
                {stagingEnv === 'prod' && !stagingViewer ? ' for review.' : '!'}
              </ModalBody>
              {!!(stagingEnv !== 'prod' || stagingViewer) && (
                <ModalFooter>
                  <StagingOutLink to={`/suggested-edits/${submission._id}`}>
                    {stagingEnv !== 'off' && stagingViewer ? (
                      <Flex align="center" gap={3} tag="b">
                        View Staged Suggestion
                        {stagingEnv === 'prod' && <Icon name="external-link" />}
                      </Flex>
                    ) : (
                      <b>View Suggestion</b>
                    )}
                  </StagingOutLink>
                </ModalFooter>
              )}
            </>
          )}
        </form>
      </Modal>
      <div className="ModalWrapper" id="suggestion-submit-modal"></div>
    </>
  );
};

SuggestionsEditor.propTypes = {
  activeDoc: PropTypes.string,
  doc: PropTypes.shape({
    body: PropTypes.string,
    excerpt: PropTypes.string,
    title: PropTypes.string,
  }),
  meta: PropTypes.object,
  sidebars: PropTypes.shape({
    docs: PropTypes.array,
  }),
};

export default SuggestionsEditor;

import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollToActiveSidebarItem() {
  const scrollAreaRef = useRef<HTMLElement>(null);
  const scrollContentRef = useRef<HTMLDivElement>(null);
  const activeItemRef = useRef<HTMLAnchorElement>(null);
  const { hash, pathname } = useLocation();

  useEffect(() => {
    let defer: ReturnType<typeof setTimeout>;

    if (activeItemRef.current) {
      // When the active item is a child of a nested list, it mounts inside a `display:none;` node.
      // So temporarily set the display of the list, check the activeItem bounding box, then unset the display property.
      const parentList = activeItemRef.current.closest('.rm-Sidebar-list') as HTMLUListElement;

      parentList.style.display = 'flex';
      const { top: activeItemTop, height: activeItemHeight } = activeItemRef.current.getBoundingClientRect();
      parentList.style.display = '';

      // Is the active item visible in the viewport?
      const isInView = activeItemTop + activeItemHeight <= window.innerHeight;

      if (!isInView && scrollContentRef.current) {
        // The scroll area is the height of the viewport but gets pushed down by the hub's header,
        // making a bottom portion of the scroll area out of view until the whole window is scrolled.
        // If the active items falls within that portion, we must also scroll the whole page by the height of the header.
        const isOutOfViewWhenScrolled =
          activeItemTop > scrollContentRef.current.clientHeight - scrollContentRef.current.offsetTop;
        const isHashLinkPresent = hash?.startsWith('#');
        if (isOutOfViewWhenScrolled && !isHashLinkPresent) {
          // Scroll the page so the whole sidebar scroll area is in view.
          window.scrollTo({ top: scrollAreaRef.current?.getBoundingClientRect().top, left: 0, behavior: 'smooth' });
        }

        const scrollTarget = isOutOfViewWhenScrolled
          ? scrollContentRef.current.clientHeight // full height of content to be scrolled
          : activeItemTop - (scrollAreaRef.current?.clientHeight || 0) / 2; // active item scrolled to the middle of the viewport

        // Defer until the callstack is complete to ensure scrollTop is applied
        defer = setTimeout(() => {
          if (scrollAreaRef.current) scrollAreaRef.current.scrollTop = scrollTarget;
        }, 0);
      }
    }

    return () => {
      if (defer) clearTimeout(defer);
    };
  }, [hash, pathname]);

  return { scrollAreaRef, scrollContentRef, activeItemRef };
}

export default useScrollToActiveSidebarItem;

/** Generate a full ReadMe subdomain URL that points to staging.
 *  @param {string}   path:     subpath to navigate to within the project
 *  @param {URL}      domain:   current ReadMe root domain (per env)
 *  @param {object}   project:  the current project context
 *  @param {boolean=} isParent: is this an ent parent? (GLP or SPE child)
 */
const generateStagingUrl = (path, domain, { parent, ...project }, isParent = false) => {
  // generate the base ReadMe URl for the current project or ent group
  const base = `http://${parent?.subdomain || project.subdomain}.${domain}`;

  // add port suffix in local env (hardcoded here b/c it's not included in config.domain)
  const port = domain.endsWith('.local') ? ':3000/' : '/';

  // append ent child subpath (except for SPE project URLs, i.e. when isParent is true)
  const proj = !isParent && parent && `${project.subpath || project.subdomain}/`;

  // force the app to load from the staging DB
  const query = '?staging=1';

  // join path parts + normalize duplicate separators
  return [base, port, proj, path, query]
    .filter(Boolean)
    .join('')
    .replace(/([^:])\/{2,}/g, '$1/');
};

export default generateStagingUrl;

export default function useIsStagingViewer(user, project) {
  if (!user || !project) return false;

  // Check if user has godmode enabled
  if (user.isGod) return true;

  // Check if the project we're taking in is the parent or not
  const isParent = !!project.childrenProjects?.length;

  // If it is the parent, we want to check if the user has any group-level permissions
  if (isParent) {
    const groupPermission = user.permissions?.find(perm => perm.project === project._id);
    // return true if they have a group permission & they're not a readonly end user
    return !!groupPermission && !(groupPermission.userType === 'readonly' && user.isEndUser);
  }

  // If the project isn't the parent, we want to find it's parent
  const parent = project._parent || project.parent;
  // Check again for group-level permissions. If any exist (group owner/admin/viewer) then the user can view staging
  if (parent) {
    const groupPermission = user.permissions?.find(perm => perm.project === parent._id);
    if (groupPermission) {
      // return true if they have a group permission & they're not a readonly end user
      return !(groupPermission.userType === 'readonly' && user.isEndUser);
    }
  }

  // Otherwise, users with permissions that are per-project can view staging for that project
  // The only exception with project-level is that readonly end users should not be able to view staging
  const projectPermission = user.permissions?.find(perm => perm.project === project._id);
  const isEndUser = projectPermission?.userType === 'readonly' && user.isEndUser;

  return !!projectPermission && !isEndUser;
}
